<template>
  <div class="text-center">
    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-auto flex-grow-0">
        <h3 class="mb-0 text-left">PSC Inspection List</h3>
      </div>
      <div class="col-sm-auto">
        <div class="row">
          <div class="col-sm-auto float-right pr-0" v-if="isAllowed('psc.index.create')">
            <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#NewPscInspection">
              <font-awesome-icon icon="plus-square" style="width: 25px"/>
              <small class="font-weight-bold px-2">
                NEW PSC INSPECTION
              </small>
            </button>
          </div>
          <div class="col-sm-auto float-right pr-0" v-if="isAllowed('psc.index.import')">
            <button class="e-btn e-btn-blue force-white-all pr-3" data-toggle="modal" data-target="#ImportPscReportModal">
              <font-awesome-icon icon="plus-square" style="width: 25px"/>
              <small class="font-weight-bold">
                IMPORT
              </small>
            </button>
          </div>
          <div class="col-sm-auto float-right" v-if="isAllowed('psc.index.download-template')">
            <template v-if="!toExport">
              <div class="input-group">
                <button class="e-btn e-btn-blue-gray force-white-all" @click="toExport=!toExport">
                  <font-awesome-icon icon="file-excel" style="width: 20px"/>
                  <small class="font-weight-bold">
                    PSC INSPECTION TEMPLATE
                  </small>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="input-group-sm">
                <input type="number" min="1" max="30" placeholder="How many # of Deficiency?" class="form-control form-control-sm" id="exportDeficiencyCount"
                       v-model="exportDeficiencyCount" required autofocus>
                <select class="text-uppercase form-control form-control-sm" id="exportVesselId" v-model="exportVesselId" required>
                  <option :value="null" disabled>— Select Vessel —</option>
                  <option v-for="vessel in vesselKeys" :key="vessel.id" :value="vessel.id">
                    {{ vessel.name }}
                  </option>
                </select>
                <div class="input-group-append">
                  <button class="form-control form-control-sm btn btn-sm btn-outline-secondary e-btn-red force-white-all" type="button"
                          @click="exportBlankTemplate(true)">
                    <font-awesome-icon icon="ban"/>
                  </button>
                  <button class="form-control form-control-sm btn btn-sm btn-outline-secondary e-btn-green force-white-all" type="button"
                          @click="exportBlankTemplate">
                    <font-awesome-icon icon="download"/>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" v-model="filters.count" id="rowCount">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center justify-content-between">
      <div class="row mb-3 no-gutters justify-content-between align-items-center">
        <div class="col-lg-12 ">
          <div class="row no-gutters align-items-center">
            <div class="col-md-auto">
              <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>
            </div>
<!--            <div class="col-md-auto px-1">-->
<!--              <select name="" id="" class="form-inline form-control form-control-sm form-control-xs" v-model="filters.fleet_id">-->
<!--                <option :value="null">&#45;&#45; ALL FLEET &#45;&#45;</option>-->
<!--                <option :value="fleet.id" v-for="fleet in fleetKeys">{{fleet.name ? fleet.name.toUpperCase() : ''}}</option>-->
<!--              </select>-->
<!--            </div>-->
            <div class="col-md-auto px-1">
              <select class="form-control form-control-sm text-uppercase" id="CountryList"  v-model="filters.country_id">
<!--                <option :value="null">—— SELECT COUNTRY ——</option>-->
                <option :value="null">—— ALL COUNTRIES ——</option>
                <option v-for="country in countryKeys" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="col-md-auto px-1">
              <select class="form-control form-control-sm text-uppercase" id="PortList" v-model="filters.port_id" :disabled="!ports.length">
<!--                <option :value="null">—— SELECT PORT ——</option>-->
                <option :value="null">—— ALL PORTS ——</option>
                <option v-for="port in ports" :key="port.id" :value="port.id">
                  {{ port.name }} {{ port.locode_wrap }}
                </option>
              </select>
            </div>
            <div class="col-md-auto px-1">
              <select class="form-control form-control-sm text-uppercase" id="fleetList" v-model="filters.fleet_id">
                <!--                <option :value="null">—— SELECT PORT ——</option>-->
                <option :value="null">-- ALL FLEET --</option>
                <template v-for="fleet in fleetKeys">
                  <option :value="fleet.id" v-if="fleet.name !== 'N/A' && fleet.code !== 'N/A'">
                    {{fleet.name ? fleet.name.toUpperCase() : ''}}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" style="height: 75vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col">VESSEL</th>
            <th class="align-middle" scope="col">MANAGER</th>
            <th class="align-middle" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle" style="width: 10em" scope="col">PORT</th>
            <th class="align-middle" style="width: 7em" scope="col">COUNTRY</th>
            <th class="align-middle" style="min-width: 9em" scope="col">INSPECTION DATE</th>
            <th class="align-middle" style="width: 1em; font-size: 11px;" scope="col">PSC REPORT</th>
            <th class="align-middle" style="min-width: 9em" scope="col">NO. DEF</th>
            <th class="align-middle" style="min-width: 9em" scope="col">DEF STATUS</th>
            <th class="align-middle" style="min-width: 9em" scope="col">DETENTION</th>
            <th class="align-middle" style="width: 12em" scope="col">RESPONSIBLE</th>
            <th class="align-middle" style="width: 12em" scope="col">PERSON IN CHARGE</th>
            <th class="align-middle" style="width: 12em" scope="col">CREATED BY</th>
            <th class="align-middle" style="width: 70px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <tr v-else-if="!finalizedReports.length && !loading">
            <td colspan="100%" class="text-center py-3">
              No records found.
            </td>
          </tr>
          <template v-else>
            <template v-for="(report, index) in finalizedReports">
              <tr :key="report.id">
                <td>{{ index + pagination.from }}</td>
                <td>{{ report.vessel }}</td>
                <td>{{ report.manager }}</td>
                <td>{{ report.title }}</td>
                <td class="align-middle text-white" :class="['bg-'+report.report_status_color]">
                  {{ report.report_status }}
                </td>
                <td>{{ report.port }}</td>
                <td>{{ report.country }}</td>
                <td>{{ report.inspection_date }}</td>
                <td>
                  <template v-if="report.pscAttachment">
                    <font-awesome-icon icon="file-pdf" v-if="report.pscAttachment.has_file" @click="viewDownloadFile(report.pscAttachment,1,'view',report.id)" size="3x"
                                       class="force-danger-all" style="cursor: pointer">
                    </font-awesome-icon>
                    <font-awesome-icon icon="file-pdf" v-else  size="3x"></font-awesome-icon>
                  </template>
                </td>
                <td class="align-middle text-center"><p class="py-0 my-0" :class="{'h5 text-danger':report.deficiencyCount > 0}">{{ report.deficiencyCount }}</p></td>
                <td class="p-2" style="width: 6rem">
                  <DeficiencyStatusIndicator :data="report.deficiencyCounts"></DeficiencyStatusIndicator>
                </td>
                <td><p class="py-0 my-0" :class="{'h5 text-danger':report.detention}">{{ report.detention ? 'YES' : 'NO' }}</p></td>
                <td>{{ report.responsible_person }}</td>
                <td>{{ report.person_in_charge }}</td>
                <td>{{ report.created_by.name }}</td>
                <td class="align-middle text-center">
                  <div class="dropdown dropright my-1">
                    <button id="actionDropdown" aria-expanded="false" aria-haspopup="true"
                            class="btn btn-sm btn-secondary force-white-all" data-toggle="dropdown">
                      <font-awesome-icon icon="cogs"/>
                    </button>
                    <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="showPscInspection(report.id)"
                              v-if="isAllowed('psc.index.view') || isAllowed('psc.main.view')">
                        <font-awesome-icon icon="eye"/>
                        View
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="showPscInspectionNewTab(report.id)"
                              v-if="isAllowed('psc.index.view') || isAllowed('psc.main.view')">
                        <font-awesome-icon icon="share"/>
                        View on New Tab
                      </button>
                      <template v-if="user.is_vessel === true">
                        <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                @click="verifyDelete(report)"
                                v-if="user.vessel_id === report.vessel_id">
                          <font-awesome-icon icon="trash" class="force-danger-all"/>
                          Delete
                        </button>
                      </template>
                      <template v-else>
                        <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                @click="verifyDelete(report)"
                                v-if="isAllowed('psc.index.delete') || user.id === report.created_by_id">
                          <font-awesome-icon icon="trash" class="force-danger-all"/>
                          Delete
                        </button>
                      </template>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <new-psc-inspection-report/>
    <import-psc-report-modal />
  </div>

  <!--  TODO: create NewPscInspection modal  -->
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import AppLoading from "@/components/elements/AppLoading";
import NewPscInspectionReport from "@/components/layouts/NewPscInspectionReport";
import {AlertService} from "../../../services/AlertService";
import {permissionMixin} from "@/mixins/permissionMixins";
import {ReportService} from "@/services/ReportService";
import _ from 'lodash';
import ImportPscReportModal from "@/components/layouts/ImportPscReportModal.vue";
import attachmentMixin from "@/mixins/attachmentMixin";
import DeficiencyStatusIndicator from "@/components/modules/psc/DeficiencyStatusIndicator.vue";

export default {
  name: "PscIndex",
  data() {
    return {
      filters: {
        page: 1,
        count: 30,
        fleet_id: null,
        status_id: null,
        office_id: null,
        port_id: null,
        country_id: null,
        flag_id: null,
        company_id: null,
        search:'',
      },
      ports:[],
      exportVesselId: null,
      exportDeficiencyCount: null,
      toExport: false,
      loading: false,
    }
  },
  components: {
    DeficiencyStatusIndicator,
    'new-psc-inspection-report': NewPscInspectionReport,
    'import-psc-report-modal': ImportPscReportModal,
    'loading': AppLoading,
  },
  // mixins: [PaginationMixin],
  mixins: [vueAppMixin, PaginationMixin, permissionMixin,attachmentMixin],
  methods: {
    ...mapActions([
      'addRequestKeys',
      'generateRequestKeys',
      'getPscReports',
      'deletePsc'
    ]),
    getPortsByCountry(id) {
      this.ports = id ? this.portKeys.filter(port => port.country_id === id) : [];
      this.filters.port_id=null;
    },
    async exportBlankTemplate(resetToDefault = false) {
      if (resetToDefault === true) {
        this.toExport = false;
        this.exportVesselId = null;
        this.exportDeficiencyCount = null;
        return;
      }

      let vesselId = this.exportVesselId;
      let deficiencyCount = this.exportDeficiencyCount;
      const deficiencyCountIsNaN = isNaN(deficiencyCount);
      const vessel = this.vesselKeys.filter(vessel => vessel.id === vesselId)[0];

      let errorMsg = "";

      if (!vessel && !deficiencyCount) {
        errorMsg = "Number of Deficiency and Vessel is required";
      } else if (!vessel) {
        errorMsg = "Please select a vessel";
      } else if (deficiencyCountIsNaN || deficiencyCount < 1 || deficiencyCount > 30 || !deficiencyCount) {
        if (deficiencyCountIsNaN) {
          errorMsg = "Number of Deficiency must be a number";
        } else if (deficiencyCount > 30) {
          errorMsg = "Number of Deficiency must be a positive number with range from 1 to 30";
        }
      }

      if (errorMsg != "") {
        AlertService.errorAlert(errorMsg, 'PSC Inspection Export');
        // await this.exportBlankTemplate(true);
        return;
      }

      swal.fire({title: 'Downloading Template... Please wait...'});
      swal.showLoading();

      deficiencyCount = parseInt(isNaN(deficiencyCount) || !deficiencyCount ? 0 : deficiencyCount);
      const vesselName = (vessel.name).toUpperCase();
      const report = 'Psc Inspection';
      const name = [report, 'Report', vesselName, 'TEMPLATE'].join(' ');

      let path = name.replaceAll(' ', '_') + '.xlsx';
      let mime_type = 'application/vnd.ms-excel';
      const response = await ReportService.downloadPscReportTemplate(vessel.id, this.exportDeficiencyCount);

      let url = window.URL.createObjectURL(new Blob([response.data], {type: mime_type}));
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', path);

      swal.close();
      document.body.appendChild(link);
      link.click();

      AlertService.successAlert('Vessel "' + vesselName + '" ' + report + ' Template has downloaded successfully', report);
      await this.exportBlankTemplate(true);
    },
    setPage(page = null) {
      this.pagination.currentPage = page ?? 1;
      this.filters.page = page ?? 1
      this.getPscReports(this.filters)
    },
    showPscInspection(inspectionId) {
      this.$router.push(
        {
          name: 'PscMain',
          params: {
            id: inspectionId
          }
        }
      )
    },
    async showPscInspectionNewTab (inspectionId) {
      const route= this.$router.resolve(
        {
          name: 'PscMain',
          params: {
            id: inspectionId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async verifyDelete(psc) {
      if (await AlertService.confirmDeleteAlert('Delete PSC', 'Do you want to delete this PSC record ?', 'Yes Proceed')) {
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            const params = {type: 1, id: psc.id,password: inputValue}
            let response = await this.deletePsc(params);
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              this.successAlert('PSC RECORD DELETED SUCCESSFULLY','DELETE PSC REPORT');
              this.setPage(1)
            }
          });
      }
    },
    initializeWatcher(){
      this.$watch(()=>this.$route.query,async (present,previous)=>{
        this.filters.page=1
        this.filters.search=present?.search
        await this.getPscReports(this.filters)
      });
    },
  },
  async created() {
    this.loading = true;
    this.initializeWatcher()

    this.addRequestKeys([
      'getVesselKeys',
      'getFleetListKeys',
      'getReportStatusKeys',
      'getMngmtOfficeKeys',
      'getCountryListKeys',
      'getMngmtCompanyKeys',
      'getTimezoneKeys',
      'getEventOneKeys',
      'getEventImpactKeys',
      'getPortKeys',
    ]);

    // @NOTE: SAMPLE @params object
    // const params = {
    //   test_param: 'this is a test',
    //   __params: {
    //     getReportStatusKeys: {
    //       id: 4,
    //     }
    //   },
    // };

    const params = {};
    await this.generateRequestKeys(params);
    await this.getPscReports(this.filters);
    this.setPage2=_.debounce(this.setPage,250);

    this.loading = false;
  },
  computed: {
    ...mapGetters([
      'isLoading', 'reports', 'vesselKeys', 'fleetKeys', 'portKeys',
      'mngmntOfficeKeys', 'countryKeys', 'reportStatusKeys', 'mngmtCompanyKeys'
    ]),
    finalizedReports() {
      let newReports = [];
      this.reports.forEach((report) => {
        let satisfiedCondition = (report.report_status_id !== 1) || (report.report_status_id === 1 && this.user.id === report.created_by_id)
        if (satisfiedCondition === true || this.isElite() === true) {
          newReports.push(report);
        }
      })

      return newReports;
    }
  },
  watch:{
     async 'filters.count'(){
      this.loading = true;
      await this.getPscReports(this.filters)
      this.loading = false;
    },
    async 'filters.fleet_id'(){
      this.loading = true;
      await this.getPscReports(this.filters)
      this.loading = false;
    },
    async 'filters.country_id'(id) {
      this.$nextTick(() => {
        this.getPortsByCountry(id);
      })
      this.loading = true;
      await this.getPscReports(this.filters)
      this.loading = false;
    },
    async 'filters.port_id'() {
      this.loading = true;
      await this.getPscReports(this.filters)
      this.loading = false;
    }
  }
}
</script>

<style scoped>
table tr td {
  font-size: 10px;
}

.force-align-top {
  display: inline-block;
  vertical-align: top;
}
/*#exportDeficiencyCount {
  max-width: 30%;
}*/

</style>
